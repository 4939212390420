// export const apiUrlBenefits = process.env.REACT_APP_API_HOST_BENEFITS;
export const apiUrlBenefits =
  process.env.REACT_APP_BENEFIT_URL || "http://api.local.com:82/api";
export const apiUrlBenefitsAdmin = apiUrlBenefits + "/admin";
export const apiCOD = process.env.REACT_APP_API_COD_BENEFIT || "CLE";

const fetcher = async (url, options) => {
  if (!url.includes("company")) {
    if (!url.includes("?")) {
      url += `?company=${apiCOD}`;
    } else {
      url += `&company=${apiCOD}`;
    }
  }
  return fetch(url, {
    method: options.method ? options.method : "GET",
    headers: options.headers,
    mode: options.mode || "cors",
    body: options?.body || null,
  })
    .then((response) =>
      response.text().then((text) => ({
        status: response.status,
        statusText: response.statusText,
        headers: response.headers,
        body: text,
      }))
    )
    .then(({ status, statusText, headers, body }) => {
      let json;
      try {
        json = JSON.parse(body);
      } catch (e) {
        // not json, no big deal
      }
      if (status < 200 || status >= 300) {
        return Promise.reject(
          new Error((json && json.message) || statusText, status, json)
        );
      }
      return Promise.resolve({ status, headers, body, json });
    });
};

export const authenticate = () => {
  let token = localStorage.getItem("token");
  return token;
};

const fetcherBuildOptions = (token, url, options) => {
  const newHeaders = {
    accept: "application/json",
    "content-type": "application/json",
  };
  if (token) {
    newHeaders.authorization = `Bearer ${token}`;
  }
  const newOptions = { ...options };
  newOptions.headers = newHeaders;
  newOptions.mode = "cors";
  return fetcher(url, newOptions);
};

export const httpClientBenefits = async (url, options = {}) => {
  // eslint-disable-next-line
  if (options.notauth == true) {
    delete options.noauth;
    return fetcherBuildOptions(null, url, options);
  } else {
    let token = await authenticate();
    if (token) {
      return fetcherBuildOptions(token, url, options);
    }
  }
};
export const sendAsFormData = async (method, resource, params) => {
  let formData = new FormData();
  for (const name in params.data) {
    formData.append(name, params.data[name] ? params.data[name] : "");
  }
  if (resource === "benefit_assets") {
    if (params.data.file) {
      params.data.file?.forEach((file) => {
        formData.append("image[]", file.rawFile);
      });
      formData.delete("file");
    } else {
      formData.delete("image");
    }
  }
  let token = await authenticate();
  const newHeaders = {
    accept: "application/json",
    authorization: `Bearer ${token}`,
  };
  const newOptions = {};
  newOptions.headers = newHeaders;
  newOptions.method = "POST";
  newOptions.mode = "cors";
  newOptions.body = formData;
  delete newOptions["data"];
  if (method === "PUT") {
    formData.append("_method", "PUT");
    resource = `${resource}/${params.id}`;
    method = "POST";
  }

  return fetch(
    `${apiUrlBenefits}/admin/${resource}?${
      params?.id ? `id=${params.id}&` : ""
    }company=${apiCOD}`,
    newOptions
  )
    .then(async (res) => {
      if (res.status >= 200 && res.status < 300) {
        return res.json();
      }
      throw await res.json();
    })
    .then((json) => {
      return {
        data: { ...params.data, id: json.id, response: json },
      };
    })
    .catch((error) => {
      let message = "";
      if (error.error && typeof error.error === "object") {
        // eslint-disable-next-line
        for (const [key, value] of Object.entries(error.error)) {
          message += ` ${value}`;
        }
      }
      throw new Error(message ? `Error: ${message}` : "ra.message.error");
    });
};
