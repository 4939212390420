import * as React from "react";
import {
  Datagrid,
  DateField,
  EditButton,
  Filter,
  List,
  ReferenceField,
  SelectField,
  SelectInput,
  TextField,
  TextInput,
  useTranslate,
} from "react-admin";
import { ActionsCreate } from "../../components/Toolbar/ActionsCreate";

const TransferFilter = (props) => (
  <Filter {...props}>
    <SelectInput
      source="status"
      label="Estado"
      alwaysOn
      choices={[
        { id: "pending", name: "Pendiente" },
        { id: "approved", name: "Aprobado" },
        { id: "rejected", name: "Rechazado" },
      ]}
    />
  </Filter>
);

export const TransferPaymentsList = ({ permissions, ...props }) => {
  const translate = useTranslate();
  return (
    <List
      filters={<TransferFilter />}
      {...props}
      actions={
        <ActionsCreate label={`${translate("resources.authors.new")}`} />
      }
      sort={{ field: "created_at", order: "DESC" }}
      bulkActionButtons={false}
      title=" "
    >
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField
          source="subscription.user_details.email"
          label="Suscriptor"
        />
        <SelectField
          source="status"
          label="Estado"
          choices={[
            { id: "pending", name: "Pendiente" },
            { id: "approved", name: "Aprobado" },
            { id: "rejected", name: "Rechazado" },
          ]}
        />
        <DateField source="created_at" label="Fecha creación" />

        {permissions &&
          permissions.some(
            (permission) =>
              permission.name === "*" ||
              permission.name === "edit-transfer-payments"
          ) && <EditButton />}
      </Datagrid>
    </List>
  );
};
