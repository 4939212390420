import React, { useState, useEffect, useCallback } from "react";
import { useVersion, useDataProvider, DateField } from "react-admin";
import {
  Typography,
  Card,
  CardContent,
  Box,
  Stepper,
  Step,
  StepLabel,
  StepContent,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MoneyIcon from "@material-ui/icons/AttachMoney";
import CalendarIcon from "@material-ui/icons/Today";
import { Fragment } from "react";
import Chip from "@material-ui/core/Chip";
import green from "@material-ui/core/colors/green";
import orange from "@material-ui/core/colors/orange";
import red from "@material-ui/core/colors/red";
import { useTranslate } from "ra-core";
import medio from "Static/data/medio.json";
const default_language = medio?.default_language
  ? `${medio?.default_language}`
  : "es";
const PAYMENT_METHOD = {
  1: "MP",
  3: "M",
  4: "OP",
  5: "F",
  6: "T",
};

const useAsideStyles = makeStyles((theme) => ({
  root: {
    width: 400,
  },
}));

const statusSwitch = {
  pending: { color: orange[400] },
  active: { color: green[600] },
  renewed: { color: green[400] },
  expired: { color: red[600] },
  cancelled: { color: orange[600] },
};

const AsideSubscriptions = ({ record }) => {
  const classes = useAsideStyles();
  return (
    <div className={classes.root}>
      {record && <EventList record={record} />}
    </div>
  );
};

const useEventStyles = makeStyles({
  stepper: {
    background: "none",
    border: "none",
    marginLeft: "0.3em",
  },
});

const EventList = ({ record }) => {
  const classes = useEventStyles();
  const dataProvider = useDataProvider();
  const [state, setState] = useState({});
  const version = useVersion();
  const translate = useTranslate();

  const getData = (subscription) => {
    const { status, payment_method } = subscription;
    const color = statusSwitch[status]?.color || orange[600];
    return (
      <Chip
        size="small"
        label={`${translate(
          `resources.subscribers.status.${status}`
        )} | ${getPaymentMethod(payment_method)}`}
        style={{ backgroundColor: color, color: "white" }}
      />
    );
  };

  const getPaymentMethod = (payment_method) => {
    return PAYMENT_METHOD[payment_method || 3] || "";
  };

  return (
    <>
      {record && record?.list && record.list.length > 0 && (
        <Fragment>
          <Box m="0 24px">
            <Typography variant="h6">
              Listado suscripciones del grupo
            </Typography>
          </Box>
          <Stepper orientation="vertical" classes={{ root: classes.stepper }}>
            {record.list.map((subscription) => {
              return (
                <Step key={`${subscription.id}`} expanded active completed>
                  <StepLabel
                    StepIconComponent={() => (
                      <MoneyIcon
                        fontSize="small"
                        color="disabled"
                        style={{ paddingLeft: 3 }}
                      />
                    )}
                  >
                    <Typography variant="body2" style={{ fontWeight: 600 }}>
                      {subscription?.plan_name}{" "}
                      <span>{getData(subscription)}</span>
                    </Typography>
                  </StepLabel>
                  <StepContent>
                    <Typography variant="body2" gutterBottom>
                      <span>
                        Fecha de pago:{" "}
                        {new Date(
                          subscription.payment_date + " 00:00:00"
                        ).toLocaleString(
                          translate("components.locale_language"),
                          {
                            timeZone:
                              default_language == "es"
                                ? "America/Argentina/Buenos_Aires"
                                : undefined,
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                          }
                        )}
                      </span>
                    </Typography>
                    {subscription.expire_date != "0000-00-00" ? (
                      <Typography variant="body2" gutterBottom>
                        <span>
                          Vencimiento:{" "}
                          {new Date(
                            subscription.expire_date + " 00:00:00"
                          ).toLocaleString(
                            translate("components.locale_language"),
                            {
                              timeZone:
                                default_language == "es"
                                  ? "America/Argentina/Buenos_Aires"
                                  : undefined,
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                            }
                          )}
                        </span>
                      </Typography>
                    ) : null}
                    <Typography variant="body2" color="textSecondary">
                      {Number(subscription?.paid_amount || 0).toLocaleString(
                        translate("components.locale_language"),
                        {
                          style: "currency",
                          currency: translate("components.currency"),
                          minimumFractionDigits: 2,
                        }
                      )}
                    </Typography>
                  </StepContent>
                </Step>
              );
            })}
          </Stepper>
        </Fragment>
      )}
    </>
  );
};

export default AsideSubscriptions;
