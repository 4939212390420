import { Create, SimpleForm, TextInput, required } from "react-admin";
import { Box, Typography } from "@material-ui/core";
import { apiCOD } from "../../../utils/utilsFetchBenefits";
import { ImageInputBenefit } from "../components/ImageInputBenefit";
import ImagePickerBenefits from "../components/ImagePickerBenefit";

export const Form = (props) => {
  return (
    <div style={{ display: "flex", gap: "16px", width: "100%" }}>
      <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <TextInput
          source="name"
          label="resources.benefit_authors.fields.name"
          validate={[required()]}
        />
        <TextInput
          source="description"
          label="resources.benefit_authors.fields.description"
          validate={[required()]}
          multiline
          inputProps={{ maxLength: 256 }}
        />
        <Box maxWidth={"300px"}>
          <ImageInputBenefit source="image" label="Avatar" />
        </Box>
        <Typography variant="h6" style={{ marginBottom: 1 }}>
          Redes sociales
        </Typography>
        <TextInput
          label="resources.benefit_authors.fields.social_links.facebook"
          source="social_networks.facebook"
        />
        <TextInput
          label="resources.benefit_authors.fields.social_links.twitter"
          source="social_networks.twitter"
        />
        <TextInput
          label="resources.benefit_authors.fields.social_links.linkedin"
          source="social_networks.linkedin"
        />
        <TextInput
          label="resources.benefit_authors.fields.social_links.instagram"
          source="social_networks.instagram"
        />

        <TextInput
          label="resources.benefit_authors.fields.social_links.tiktok"
          source="social_networks.tiktok"
        />

        <ImagePickerBenefits {...props} />
      </div>
    </div>
  );
};

export const AuthorsCreate = ({ ...props }) => {
  const transform = (values) => {
    let result = { ...values };
    result.company = apiCOD;
    return result;
  };

  return (
    <Create title=" " redirect="list" transform={transform} {...props}>
      <SimpleForm>
        <Form {...props} />
      </SimpleForm>
    </Create>
  );
};
