import * as React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  required,
  ReferenceInput,
  SelectInput,
  ArrayInput,
  BooleanInput,
  minValue,
  number,
  useTranslate,
  FormDataConsumer,
  SaveButton,
  DeleteWithConfirmButton,
  Toolbar,
} from "react-admin";
import { InputAdornment, Box, Typography } from "@material-ui/core";
import CustomIterator from "./CustomIterator";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
});

const formStyles = makeStyles({
  boxCard: {
    backgroundColor: "#fff",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
  switcher: {
    "& p": {
      display: "none",
    },
  },
});
const CustomToolbar = (props) => (
  <Toolbar classes={useStyles()} {...props}>
    <SaveButton disabled={props.pristine} />
    <DeleteWithConfirmButton
      confirmTitle="¿Querés cancelar este plan?"
      confirmContent="Los suscriptores adheridos a este plan seguirán activos. Si no querés continuar cobrándoles, podés cancelarlos desde la opción Suscriptores."
    />
  </Toolbar>
);

export const PlansEdit = (props) => {
  const translate = useTranslate();
  const classes = formStyles();

  return (
    <Edit title=" " {...props}>
      <SimpleForm toolbar={<CustomToolbar />}>
        <Box display={{ xs: "block", sm: "flex" }} style={{ width: "100%" }}>
          <Box mr={{ xs: 0, sm: "0.5em" }}>
            <TextInput disabled source="id" />
          </Box>
          <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
            <TextInput
              disabled
              source="external_reference"
              fullWidth
              style={{ maxWidth: "328px" }}
            />
          </Box>
        </Box>
        <TextInput source="name" validate={[required()]} />
        <SelectInput
          source="type"
          defaultValue="monthly"
          choices={[
            {
              id: "yearly",
              name: "Anual",
            },
            {
              id: "monthly",
              name: "Mensual",
            },
          ]}
        />
        <Box
          display={{ xs: "block", sm: "flex" }}
          style={{ width: "100%", alignItems: "flexStart" }}
        >
          <Box mr={{ xs: 0, sm: "0.5em" }}>
            <NumberInput
              resource={"plans"}
              source={"price"}
              validate={[required(), number(), minValue(0)]}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </Box>
          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.type === "yearly" &&
                formData.price > 0 &&
                !formData.price_discount &&
                formData?.limit == 0 ? (
                  <Alert
                    style={{ marginBottom: "1em", display: "inline-flex" }}
                    severity="info"
                  >
                    {translate("resources.plans.helper.price", {
                      price: formData.price * 12,
                    })}
                  </Alert>
                ) : formData.type === "yearly" &&
                  formData.price > 0 &&
                  !formData.price_discount &&
                  formData?.limit > 0 ? (
                  <Alert
                    style={{ marginBottom: "1em", display: "inline-flex" }}
                    severity="info"
                  >
                    {translate("resources.plans.helper.price", {
                      price: formData.price * 12 * formData?.limit,
                    })}
                  </Alert>
                ) : null
              }
            </FormDataConsumer>
          </Box>
        </Box>
        <Box
          display={{ xs: "block", sm: "flex" }}
          style={{ width: "100%", alignItems: "center" }}
        >
          <Box mr={{ xs: 0, sm: "0.5em" }}>
            <NumberInput
              resource={"plans"}
              source={"price_discount"}
              helperText={translate("resources.plans.helper.price_discount")}
              validate={[number(), minValue(0)]}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </Box>
          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <FormDataConsumer>
              {({ formData, ...rest }) =>
                formData.type === "yearly" &&
                formData.price > 0 &&
                formData.price_discount && (
                  <Alert
                    style={{ marginBottom: "1em", display: "inline-flex" }}
                    severity="info"
                  >
                    {translate("resources.plans.helper.price", {
                      price: formData.price_discount * 12,
                    })}
                  </Alert>
                )
              }
            </FormDataConsumer>
          </Box>
        </Box>
        <Box
          display={{ xs: "block", sm: "flex" }}
          my={1}
          style={{
            flexDirection: "column",
            width: "100%",
            maxWidth: "450px",
          }}
        >
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              if (
                !("has_free_trial" in formData) &&
                (formData?.free_trial_type == "months" ||
                  formData?.free_trial_type == "days")
              ) {
                formData.has_free_trial = true;
              }
              return (
                formData.payment_gateway &&
                formData.payment_gateway === "mercadopago" && (
                  <Box
                    display={{ xs: "block", sm: "flex" }}
                    borderRadius={2}
                    padding={2}
                    marginBottom={2}
                    border={"1px solid #e5e5e5"}
                    style={{
                      flexDirection: "column",
                    }}
                    className={classes.boxCard}
                  >
                    <Typography
                      variant="h6"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      {translate("resources.plans.free_trial_mp.title")}
                    </Typography>
                    <BooleanInput
                      source="has_free_trial"
                      resource="plans"
                      initialValue={
                        formData?.free_trial_type == "months" ||
                        formData?.free_trial_type == "days"
                          ? true
                          : false
                      }
                      className={classes.switcher}
                    />
                    {formData?.has_free_trial && (
                      <Box
                        display={{ xs: "block", sm: "flex" }}
                        flexDirection={{ sm: "row" }}
                        style={{
                          gap: "12px",
                        }}
                      >
                        <SelectInput
                          label="Prueba gratis"
                          source="free_trial_type"
                          defaultValue={"days"}
                          choices={[
                            {
                              id: "days",
                              name: "Días",
                            },
                            {
                              id: "months",
                              name: "Meses",
                            },
                          ]}
                        />
                        <Box mr={{ xs: 0, md: "0.5em" }}>
                          <NumberInput
                            resource="plans"
                            label="Duración de prueba"
                            source={"free_trial_duration"}
                            validate={[required(), number(), minValue(1)]}
                          />
                        </Box>
                      </Box>
                    )}
                  </Box>
                )
              );
            }}
          </FormDataConsumer>
        </Box>
        <ReferenceInput
          source="role_id"
          reference="roles"
          sort={{ field: "title", order: "ASC" }}
          perPage={1000}
          filterToQuery={() => ({
            type: "subscriber",
          })}
        >
          <SelectInput optionText="title" validate={required()} />
        </ReferenceInput>
        <BooleanInput
          source="recommend"
          resource="plans"
          initialValue={false}
        />

        <BooleanInput
          fullWidth
          source="locally"
          resource="plans"
          initialValue={false}
        />
        <Box>
          <BooleanInput
            fullWidth
            source="corporative"
            resource="plans"
            label="Es corporativo"
          />
        </Box>
        <Box>
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData?.corporative ? (
                <NumberInput
                  fullWidth
                  source="limit"
                  resource="plans"
                  label="Cupos"
                  defaultValue={0}
                  helperText="Cantidad contando al titular"
                />
              ) : null
            }
          </FormDataConsumer>
        </Box>
        <FormDataConsumer>
          {({ formData, ...rest }) =>
            formData.external_reference ? (
              <BooleanInput
                source="active"
                resource="plans"
                initialValue={true}
              />
            ) : (
              <>
                <BooleanInput
                  source="active"
                  resource="plans"
                  initialValue={false}
                  disabled
                />
                <Alert
                  style={{ marginBottom: "1em", display: "inline-flex" }}
                  severity="info"
                >
                  {translate("resources.plans.helper.active")}
                </Alert>
              </>
            )
          }
        </FormDataConsumer>

        <ArrayInput label="" source="lines_description">
          <CustomIterator
            label="Detalles del plan"
            source="lines_description"
          />
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};
