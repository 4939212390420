import {
  Create,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  useNotify,
} from "react-admin";
import { Typography } from "@material-ui/core";

export const FormCreate = ({ ...props }) => {
  return (
    <SimpleForm
      {...props}
      initialValues={{ social_links: props?.record ? props.record : {} }}
    >
      <Typography variant="h6" style={{ marginBottom: 1 }}>
        Suscription grupal
      </Typography>
      <SelectInput
        source="status"
        choices={[
          { id: "pending", name: "Pendiente" },
          { id: "active", name: "Activa" },
          { id: "cancelled", name: "Cancelada" },
        ]}
      />
      <TextInput source="external_user_id" label="Id página " />
    </SimpleForm>
  );
};
export const SubscriptionGroupCreate = ({ ...props }) => {
  const notify = useNotify();

  return (
    <Create title=" " {...props}>
      <FormCreate {...props} />
    </Create>
  );
};
