import { DeleteWithConfirmButton, SaveButton, Toolbar } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslate } from "ra-core";
import { checkPermissions } from "../../utils/validations";

const useStyles = makeStyles({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
});
export const ActionSaveDelete = ({
  permissionDelete,
  permissionSave,
  permissions,
  ...props
}) => {
  //const record = useRecordContext();
  const translate = useTranslate();
  return (
    <Toolbar classes={useStyles()} {...props}>
      {checkPermissions(permissions, permissionSave) ? <SaveButton /> : null}
      {checkPermissions(permissions, permissionDelete) ? (
        <DeleteWithConfirmButton
          confirmTitle={`${translate("resources.workshop.confirm_title")}`}
          confirmContent={`${translate("resources.workshop.confirm_content")}`}
          //translateOptions={{ name: record.name }}
        />
      ) : null}
    </Toolbar>
  );
};
