import React, { useEffect } from "react";
import { AutocompleteInput } from "react-admin";
import { useField } from "react-final-form";

const LocationSelect = ({ getSource, locations, scopedFormData }) => {
  const sourceProvince = getSource("province_id");
  const sourceCity = getSource("city_id");
  const sourceLocation = getSource("location_id");
  const sourceIsOnline = getSource("is_online");
  const sourcePlace = getSource("place");

  const { input: inputProvince } = useField(sourceProvince || "");
  const { input: inputCity } = useField(sourceCity || "");
  const { input: inputLocation } = useField(sourceLocation || "");
  const { input: inputPlace } = useField(sourcePlace || "");
  const { input: inputIsOnline } = useField(sourceIsOnline || "");

  const updateField = (field, value) => {
    if (field?.onChange && field?.value !== value) {
      field.onChange(value);
    }
  };

  const isEmpty = () => {
    return !scopedFormData || Object.keys(scopedFormData).length === 0;
  };

  useEffect(() => {
    if (isEmpty()) return;

    if (inputPlace?.value && locations) {
      const item = locations.find((i) => i?.description === inputPlace?.value);
      if (item) {
        if (inputIsOnline?.value) {
          updateField(inputIsOnline, false);
        }
        updateField(inputProvince, item.province_id || null);
        updateField(inputCity, item.city_id || null);
        updateField(inputLocation, item.id || null);
      }
    }
  }, [inputPlace?.value, locations]);

  //   useEffect(() => {
  //     if (isEmpty()) return;

  //     if (inputLocation?.value && locations) {
  //       const item = locations.find((i) => i?.id === inputLocation?.value);
  //       if (item && item?.description !== inputPlace?.value) {
  //         updateField(inputPlace, item.description || null);
  //       }
  //     }
  //   }, [inputLocation?.value, locations]);

  return (
    <AutocompleteInput
      choices={locations}
      source={sourcePlace}
      label="resources.workshop.fields.workshopdates.place"
      optionText={(value) =>
        `${value?.description} - ${value?.name} - ${value?.city?.name}`
      }
      optionValue="description"
      fullWidth
    />
  );
};

export default LocationSelect;
