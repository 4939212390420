import {
  Create,
  SimpleForm,
  TextInput,
  required,
  NumberInput,
  useQueryWithStore,
  AutocompleteInput,
  Loading,
  SelectInput,
} from "react-admin";
import { Box, Typography } from "@material-ui/core";
import { apiCOD } from "../../../utils/utilsFetchBenefits";
import medio from "Static/data/medio.json";
import { useEffect, useState } from "react";
import { useFormState } from "react-final-form";

export const Form = () => {
  const [provinces, setProvinces] = useState();
  const { values } = useFormState();
  const { data: cities } = useQueryWithStore({
    type: "getList",
    resource: "cities",
    payload: {
      filter: {},
      sort: { field: "name", order: "ASC" },
      pagination: { page: 1, perPage: 10000 },
    },
  });
  const { data: businesses } = useQueryWithStore({
    type: "getList",
    resource: "business",
    payload: {
      filter: {},
      sort: { field: "name", order: "ASC" },
      pagination: { page: 1, perPage: 10000 },
    },
  });

  useEffect(() => {
    if (cities) {
      let list = {};
      cities?.forEach((obj) => {
        list[obj?.province?.id] = obj?.province;
      });
      setProvinces(Object.values(list));
    }
  }, [cities]);

  return (
    <>
      <Typography variant="h6" mb="1">
        Lugar
      </Typography>
      <div style={{ display: "flex", gap: "16px", width: "100%" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {provinces ? (
            <Box>
              <SelectInput
                source="province_id"
                choices={provinces}
                label="resources.provinces.fields.name"
              />
            </Box>
          ) : null}
          {cities && values?.province_id ? (
            <Box>
              <AutocompleteInput
                source="city_id"
                label={
                  medio?.translations?.city || "resources.cities.fields.name"
                }
                // eslint-disable-next-line
                choices={cities?.filter(
                  (i) => i?.province_id == values?.province_id
                )}
                validate={[required()]}
                optionText="name"
                suggestionLimit={10}
                optionValue="id"
              ></AutocompleteInput>

              <AutocompleteInput
                source="business_id"
                label="Negocio"
                choices={businesses}
                optionText="name"
                optionValue="id"
              ></AutocompleteInput>

              <TextInput source={"description"} label="Nombre" />
              <Box display="flex" style={{ gap: "10px" }}>
                <TextInput
                  source={"name"}
                  label="Dirección"
                  validate={[required()]}
                />
                <TextInput
                  source={"opening_hours"}
                  label="Horario Atención"
                  multiline
                />
              </Box>
              <Box display="flex" style={{ gap: "10px" }}>
                <NumberInput
                  source={"lat"}
                  label="Latitud"
                  validate={[required()]}
                />
                <NumberInput
                  source={"long"}
                  label="Longitud"
                  validate={[required()]}
                />
              </Box>
            </Box>
          ) : null}
          {!cities ? <Loading /> : null}
        </div>
      </div>
    </>
  );
};

export const LocationsCreate = ({ ...props }) => {
  const transform = (values) => {
    let result = { ...values };
    result.company = apiCOD;
    return result;
  };

  return (
    <Create title=" " redirect="list" transform={transform} {...props}>
      <SimpleForm>
        <Form />
      </SimpleForm>
    </Create>
  );
};
