import {
  Create,
  SimpleForm,
  TextInput,
  required,
  maxLength,
  useQueryWithStore,
  Loading,
  useRecordContext,
  AutocompleteInput,
  NumberInput,
  ArrayInput,
  SimpleFormIterator,
  BooleanInput,
  FormDataConsumer,
  DateTimeInput,
  AutocompleteArrayInput,
  SelectInput,
  ReferenceInput,
} from "react-admin";
import { apiCOD } from "../../../utils/utilsFetchBenefits";
import { useField } from "react-final-form";
import { Box, Typography, Divider } from "@material-ui/core";
import { ImageInputBenefit } from "../components/ImageInputBenefit";
import ImagePickerBenefits from "../components/ImagePickerBenefit";
import { makeStyles } from "@material-ui/styles";
import { useEffect } from "react";
import { TooltipComponent } from "../../../components/TooltipComponent/TooltipComponent";
import RichTextInput from "ra-input-rich-text";
import LocationSelect from "../components/LocationSelect";

const useStyles = makeStyles((theme) => ({
  boxCard: {
    boxSizing: "border-box",
    border: "1px solid #e5e5e5",
    gap: "10px",
    display: "flex",
    flexDirection: "row",
    borderRadius: "4px",
    padding: "16px",
    marginBottom: "16px",
    backgroundColor: "#fff",
    width: "100%",
    boxShadow:
      "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
  },
  mobileFlexBox: { flexDirection: "column", gap: "8px", maxWidth: "550px" },
  [theme.breakpoints.up("md")]: {
    flexCards: {
      display: "flex",
      flexDirection: "row",
      gap: "4px",
    },
    rowGallery: {
      display: "flex",
      flexDirection: "row",
      gap: "8px",
    },
    mobileFlexBox: { maxWidth: "1150px", flexDirection: "column" },
    joinedInputs: { display: "flex", flexDirection: "row", gap: "16px" },
    fullWidthDesktop: { width: "100%" },
  },
  hidden: {
    display: "none",
  },
  columnFlex: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
}));

export const Form = (props) => {
  const record = useRecordContext();
  const { input: input_categories } = useField("categories");
  const { input: input_workshopdates } = useField("workshopdates");

  const classes = useStyles();

  const { data: locations } = useQueryWithStore({
    type: "getList",
    resource: "locations",
    payload: {
      filter: {},
      sort: { field: "name", order: "ASC" },
      pagination: { page: 1, perPage: 10000 },
    },
  });
  const { data: authors } = useQueryWithStore({
    type: "getList",
    resource: "benefit_authors",
    payload: {
      filter: {},
      sort: { field: "name", order: "ASC" },
      pagination: { page: 1, perPage: 10000 },
    },
  });

  const { data: categories } = useQueryWithStore({
    type: "getList",
    resource: "benefit_categories",
    payload: {
      filter: {},
      sort: { field: "name", order: "ASC" },
      pagination: { page: 1, perPage: 10000 },
    },
  });

  useEffect(() => {
    if (!record) return;
    if (record?.categories) {
      const categories = record?.categories?.map((e) => e.id);
      input_categories.onChange(categories);
    }
    if (record?.workshopdates) {
      const newDatesArray = [];
      record?.workshopdates.forEach((e) => {
        newDatesArray.push({
          id: e.id,
          date: e.date,
          link: e.link,
          quotes: e.quotes !== 0 ? e.quotes : null,
          place: e.place,
          is_online: Boolean(e.is_online),
          location_id: e.location_id,
          city_id: e.location?.city_id,
          province_id: e.location?.city?.province_id,
          schedule: e.schedule,
        });
      });
      input_workshopdates.onChange(newDatesArray);
    }
  }, [record]);

  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    // Refocus immediately, on the next tick (after the current function is done)
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box
        className={`${classes.boxCard} ${classes.mobileFlexBox} ${classes.joinedInputs}`}
      >
        <Box className={classes.fullWidthDesktop}>
          <Typography variant="h6">Información del taller</Typography>
          <TooltipComponent
            infoCheck
            fullWidth
            label={"Texto de mayor tamaño e impacto. Se muestra en la portada"}
          >
            <TextInput
              source="name"
              label="resources.workshop.fields.name"
              validate={[required()]}
              fullWidth
            />
          </TooltipComponent>
        </Box>

        <Box className={classes.fullWidthDesktop}>
          <Typography style={{ lineHeight: "32px" }} variant="h6">
            Autor
          </Typography>
          {authors ? (
            <AutocompleteInput
              source="author_id"
              label="resources.workshop.fields.author"
              choices={authors}
              optionText="name"
              optionValue="id"
              fullWidth
              resettable
            ></AutocompleteInput>
          ) : (
            <Loading />
          )}
        </Box>
        <Box className={classes.fullWidthDesktop}>
          <Typography variant="h6">Categoría/s del taller</Typography>
          {categories ? (
            <AutocompleteArrayInput
              source="categories"
              label="resources.benefits.fields.categories"
              choices={categories}
              validate={[required()]}
              multiple
              fullWidth
            />
          ) : null}
        </Box>
      </Box>
      <Box className={`${classes.boxCard} ${classes.mobileFlexBox}`}>
        <Box style={{ display: "flex", gap: "10px" }}>
          <SelectInput
            source="status"
            choices={[
              { id: "draft", name: "Borrador" },
              { id: "active", name: "Activo" },
              // { id: "disabled", name: "Deshabilitado" },
              { id: "finished", name: "Finalizado" },
              { id: "cancelled", name: "Cancelado" },
              { id: "soldout", name: "Agotado" },
              { id: "last_tickets", name: "Últimos tickets" },
            ]}
            initialValue={"draft"}
            label="Estado"
          />
          <Box>
            <ReferenceInput
              source="exclusive_plan_id"
              reference="plans"
              label="Plan exclusivo"
              resettable
            >
              <AutocompleteInput optionText="name" optionValue="id" />
            </ReferenceInput>
          </Box>
        </Box>
        <TooltipComponent
          disableFullWidth
          label={"De estar habilitado, se mostrará en la portada principal"}
        >
          <Box style={{ width: "fit-content" }}>
            <BooleanInput
              source="show_home"
              label="resources.benefits.fields.show_home"
              resource="benefits"
              initialValue={true}
            />
          </Box>
        </TooltipComponent>
        <Typography variant="h6">
          Configuración carrusel principal home
        </Typography>
        <Box style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <TooltipComponent
            disableFullWidth
            label={
              "Permite que el taller sea mostrado en el carrusel principal"
            }
          >
            <Box style={{ width: "350px" }}>
              <BooleanInput
                source="is_featured"
                label="resources.benefits.fields.is_featured"
                resource="benefits"
                initialValue={false}
              />
            </Box>
          </TooltipComponent>
          <FormDataConsumer>
            {({ formData }) => {
              return formData?.is_featured ? (
                <TooltipComponent
                  label={
                    "Permite que se muestre en el carrusel principal solo la imagen full width"
                  }
                >
                  <Box>
                    <BooleanInput
                      source="fullwidth"
                      label="resources.benefits.fields.fullwidth"
                      resource="benefits"
                      initialValue={false}
                    />
                  </Box>
                </TooltipComponent>
              ) : null;
            }}
          </FormDataConsumer>
        </Box>

        <FormDataConsumer>
          {({ formData }) => {
            return (
              <Box className={!formData?.is_featured ? classes.hidden : ""}>
                <ImageInputBenefit
                  label={`Imagen a mostrar en desktop ${
                    formData.fullwidth ? "1260x412px" : "513x303px"
                  }`}
                  source="desktop_banner"
                />
                <ImageInputBenefit
                  label={`Imagen a mostrar en mobile  ${
                    formData.fullwidth ? "300x542px" : "260x218px"
                  }`}
                  source="mobile_banner"
                />
              </Box>
            );
          }}
        </FormDataConsumer>
      </Box>
      <Box className={`${classes.boxCard} ${classes.mobileFlexBox}`}>
        <Typography variant="h6">Descripción del taller</Typography>
        <Box className={classes.joinedInputs}>
          <TooltipComponent
            infoCheck
            label={"Breve descripción del taller. Se muestra en la portada"}
          >
            <RichTextInput
              label="resources.workshop.fields.short_description"
              source="short_description"
              variant="filled"
              toolbar={[["bold", "italic", "link"]]}
              validate={[required(), maxLength(180)]}
              fullWidth
              multiline
              inputProps={{ maxLength: 180 }}
              className={classes.fullWidthDesktop}
            />
          </TooltipComponent>
          <TooltipComponent
            infoCheck
            label={
              "Texto que se muestra una vez que el usuario desea conocer mejor el taller en cuestión"
            }
          >
            <RichTextInput
              label="resources.workshop.fields.description"
              source="description"
              variant="filled"
              toolbar={[["bold", "italic", "link"]]}
              validate={[required()]}
              fullWidth
              inputProps={{ maxLength: 2000 }}
              className={classes.fullWidthDesktop}
              multiline
            />
          </TooltipComponent>
        </Box>
      </Box>

      <Box
        className={`${classes.boxCard} ${classes.mobileFlexBox} ${classes.flexCards}`}
      >
        <Box style={{ maxWidth: "550px" }}>
          <ImageInputBenefit label="Imagen Principal" />
        </Box>
        <ImageInputBenefit
          source="gallery"
          multiple={true}
          label="Galería de imágenes"
          className={classes.flexCards}
          galleryClasses={classes.rowGallery}
        />
      </Box>
      <ImagePickerBenefits {...props} />
      <TooltipComponent
        label={
          "Cuando haga click en subscribirse redirigirá a la página indicada."
        }
      >
        <TextInput source={"redirect"} label="Redirigir a otra página" />
      </TooltipComponent>
      <TooltipComponent
        label={"En caso de mostrar solamente un popup con descuento"}
      >
        <TextInput source={"code"} label="Código descuento" />
      </TooltipComponent>
      <TooltipComponent
        label={"Descripción que se mostrara junto con el código de descuento."}
      >
        <RichTextInput
          label="Descripción del descuento"
          source="description_code"
          variant="filled"
          toolbar={[["bold", "italic", "link"]]}
          fullWidth
          inputProps={{ maxLength: 2000 }}
          className={classes.fullWidthDesktop}
          multiline
        />
      </TooltipComponent>
      <Divider style={{ margin: "0 0 16px 0" }} />
      <Typography variant="h6">Fecha y locación</Typography>
      <ArrayInput source="workshopdates" label=" ">
        <SimpleFormIterator
          getItemLabel={(index) => ""}
          TransitionProps={{ enter: false, exit: false }}
          disableReordering
        >
          <FormDataConsumer>
            {({ getSource, scopedFormData }) => {
              return (
                <Box display={"flex"} flexDirection="column">
                  <Box display="flex" style={{ gap: "10px" }}>
                    <Box>
                      <DateTimeInput
                        source={getSource("date")}
                        label="resources.workshop.fields.workshopdates.date"
                        validate={[required()]}
                      />
                    </Box>
                    <TooltipComponent
                      infoCheck
                      disableFullWidth
                      label={"En caso de tener un stock limitado"}
                    >
                      <NumberInput
                        source={getSource("quotes")}
                        label="resources.workshop.fields.workshopdates.quotes"
                        onWheel={numberInputOnWheelPreventChange}
                      />
                    </TooltipComponent>
                    {/*
                    <NumberInput
                      source={getSource("taked_quotes")}
                      label='resources.workshop.fields.workshopdates.taked_quotes'
                    /> */}
                  </Box>
                  <TooltipComponent label={"En caso de ser un taller online"}>
                    <TextInput
                      source={getSource("link")}
                      label="resources.workshop.fields.workshopdates.link"
                    />
                  </TooltipComponent>
                  <TooltipComponent label={"link al cronograma del taller"}>
                    <TextInput
                      source={getSource("schedule")}
                      label="resources.workshop.fields.workshopdates.schedule"
                    />
                  </TooltipComponent>

                  <Box
                    className={classes.boxCard}
                    style={{ flexDirection: "column", gap: "16px" }}
                  >
                    <Typography variant="h5">Dirección</Typography>
                    <Box display={"flex"} style={{ gap: "16px" }}>
                      <BooleanInput
                        source={getSource("is_online")}
                        label="resources.workshop.fields.online"
                      />
                    </Box>
                    <TooltipComponent
                      label={"En caso de tratarse de un taller presencial"}
                    >
                      <LocationSelect
                        getSource={getSource}
                        locations={locations}
                        scopedFormData={scopedFormData}
                      />
                    </TooltipComponent>
                    <Box display={"flex"} style={{ gap: "16px" }}>
                      <BooleanInput
                        source={getSource("show_special_form")}
                        label="Mostrar formulario para añadir acompañante"
                      />
                    </Box>
                    {/* {!scopedFormData?.is_online && (
                      <Box display={"flex"} style={{ gap: "40px" }}>
                        <Box
                          display={"flex"}
                          flexDirection={"column"}
                          style={{ width: "100%" }}
                        >
                          {provinces ? (
                            <AutocompleteInput
                              source={getSource("province_id")}
                              label="resources.workshop.fields.province"
                              choices={provinces}
                              validate={[required()]}
                              optionText="name"
                              optionValue="id"
                              fullWidth
                              multiple
                            />
                          ) : (
                            <Loading />
                          )}
                          {scopedFormData.province_id ? (
                            <AutocompleteInput
                              source={getSource("city_id")}
                              label="resources.workshop.fields.city"
                              choices={getCities(
                                scopedFormData.province_id,
                                provinces
                              )}
                              validate={[required()]}
                              optionText="name"
                              optionValue="id"
                              fullWidth
                              multiple
                            />
                          ) : null}
                          {scopedFormData.province_id &&
                          scopedFormData.city_id ? (
                            <AutocompleteInput
                              choices={getLocations(
                                scopedFormData?.city_id,
                                locations
                              )}
                              source={getSource("location_id")}
                              label="Local"
                              validate={[required()]}
                              optionText="name"
                              optionValue="id"
                              fullWidth
                            />
                          ) : null}
                        </Box>
                      </Box>
                    )} */}
                  </Box>
                  {/* TODO FILES select */}
                </Box>
              );
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </Box>
  );
};

const validateDate = (workshopdate) => {
  const value = workshopdate?.date;
  if (!value) {
    return "Este campo es obligatorio"; // Mensaje si la fecha está vacía
  }
  const now = new Date();

  if (typeof value === "string") {
    // Verificamos si la fecha está en el formato correcto
    const parsedDate = Date.parse(value); // Parseamos la fecha
    if (isNaN(parsedDate)) {
      return "La fecha debe estar en el formato correcto (YYYY-MM-DD)"; // Mensaje si el formato es incorrecto
    }

    // Convertimos la fecha a UTC y extraemos solo la parte de la fecha
    const date = new Date(parsedDate);
    if (date < now && !workshopdate?.id) {
      return "La fecha no puede ser anterior a hoy";
    }

    const isoString = date.toISOString().split("T")[0]; // Obtenemos la parte de la fecha en UTC sin la hora

    // Comparamos solo la parte de la fecha (sin la hora)
    if (isoString !== value.split("T")[0]) {
      return "La fecha debe estar en el formato correcto (YYYY-MM-DD)"; // Si no coincide el formato
    }
  }

  if (typeof value === "object") {
    if (isNaN(value.getTime())) {
      return "La fecha es inválida";
    }
    if (value < now && !workshopdate?.id) {
      return "La fecha no puede ser anterior a hoy";
    }
    const isoString = value.toISOString().split("T")[0]; // Obtenemos la parte de la fecha en UTC sin la hora

    // Comparamos solo la parte de la fecha (sin la hora)
    if (isoString !== value.toISOString().split("T")[0]) {
      return "La fecha debe estar en el formato correcto (YYYY-MM-DD)"; // Si no coincide el formato
    }
  }

  return undefined; // No hay errores
};

export const validate = (values) => {
  const errors = {};

  // Validación para "name" (nombre del taller)
  if (!values?.name) {
    errors.name = "El nombre del taller es obligatorio";
  }

  // // Validación para "author_id" (autor)
  // if (!values.author_id) {
  //   errors.author_id = "El autor es obligatorio";
  // }

  // Validación para "categories" (categorías)
  if (!values?.categories || values?.categories?.length === 0) {
    errors.categories = "Debe seleccionar al menos una categoría";
  }

  // Validación para "status" (estado)
  if (!values?.status) {
    errors.status = "El estado es obligatorio";
  }

  // // Validación para "exclusive_plan_id" (plan exclusivo)
  // if (!values.exclusive_plan_id) {
  //   errors.exclusive_plan_id = "El plan exclusivo es obligatorio";
  // }

  // Validación para "short_description" (descripción corta)
  if (!values?.short_description) {
    errors.short_description = "La descripción corta es obligatoria";
  } else if (values.short_description.length > 180) {
    errors.short_description =
      "La descripción corta no debe exceder los 180 caracteres";
  }

  // Validación para "description" (descripción completa)
  if (!values?.description) {
    errors.description = "La descripción completa es obligatoria";
  }

  // Validación para "redirect" (redirección a otra página)
  if (values?.redirect && !/^https?:\/\/[^\s]+$/.test(values?.redirect)) {
    errors.redirect = "La URL debe ser válida";
  }

  // Validación para "code" (código descuento)
  if (values?.code && values?.code?.length > 0 && values?.code?.length < 4) {
    errors.code = "El código descuento debe tener al menos 4 caracteres";
  }

  // Validación para "description_code" (descripción del descuento)
  if (values?.description_code && values?.description_code.length > 2000) {
    errors.description_code =
      "La descripción del descuento no debe exceder los 2000 caracteres";
  }

  // Validación para las fechas y locación

  values?.workshopdates?.forEach((workshopdate, index) => {
    if (workshopdate) {
      const dateError = validateDate(workshopdate);
      if (dateError) {
        if (!errors?.workshopdates) {
          errors.workshopdates = [];
        }
        errors.workshopdates[index] = { date: dateError };
      }
    }
    // if (!workshopdate?.quotes) {
    //   if (!errors.workshopdates) errors.workshopdates = [];
    //   errors.workshopdates[index] = {
    //     ...errors.workshopdates[index],
    //     quotes: "La cantidad de cupos es obligatoria",
    //   };
    // }
    // if (workshopdate.is_online && !workshopdate.link) {
    //   if (!errors.workshopdates) errors.workshopdates = [];
    //   errors.workshopdates[index] = {
    //     ...errors.workshopdates[index],
    //     link: "El link del taller online es obligatorio",
    //   };
    // }
    if (!workshopdate?.is_online && !workshopdate?.place) {
      if (!errors.workshopdates) {
        errors.workshopdates = [];
      }
      errors.workshopdates[index] = {
        ...errors.workshopdates[index],
        place: "El lugar es obligatorio, si no es online",
      };
    }
  });
  return errors;
};

export const WorkshopCreate = ({ ...props }) => {
  const transform = (values) => {
    let copy = { ...values };
    copy.company = apiCOD;
    if (!values?.workshopdates) {
      copy.workshopdates = [];
    }
    return copy;
  };
  return (
    <Create title=" " transform={transform} {...props}>
      <SimpleForm redirect="edit" validate={validate}>
        <Form {...props} />
      </SimpleForm>
    </Create>
  );
};
