import { Create, SimpleForm, TextInput, required } from "react-admin";
import { apiCOD } from "../../../utils/utilsFetchBenefits";
import medio from "Static/data/medio.json";

export const Form = () => {
  return (
    <div style={{ display: "flex", gap: "16px", width: "100%" }}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <TextInput
          source="name"
          label={
            medio?.translations?.province || "resources.provinces.fields.name"
          }
          validate={[required()]}
        />
      </div>
    </div>
  );
};

export const ProvincesCreate = ({ ...props }) => {
  const transform = (values) => {
    let result = { ...values };
    result.company = apiCOD;
    return result;
  };

  return (
    <Create title=" " redirect="list" transform={transform} {...props}>
      <SimpleForm>
        <Form />
      </SimpleForm>
    </Create>
  );
};
