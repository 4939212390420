import {
  Edit,
  SelectInput,
  SimpleForm,
  useDelete,
  useNotify,
  useRefresh,
  Confirm,
  DateInput,
  DateField,
} from "react-admin";
import { Typography, Button } from "@material-ui/core";
import { useState } from "react";
import AsideSubscriptions from "./AsideSubscriptions";

export const FormEdit = ({ ...props }) => {
  const [deleteOne, { loading, error }] = useDelete();
  const [open, setOpen] = useState();
  const refresh = useRefresh();
  const notify = useNotify();

  const handleClick = (id) => {
    setOpen(id);
  };

  const handleDelete = async () => {
    let resp = await deleteOne(
      "subscription_group_association",
      open,
      undefined,
      {
        onSuccess: () => {
          refresh();
          setOpen();
          notify("Quitado correctamente", { type: "success" });
        },
        onFailure: () => {
          setOpen(); 
          notify("Error al quitar la suscripción", { type: "info" });
        },
      }
    );
  };

  return (
    <SimpleForm {...props}>
      <Typography variant="h6" style={{ marginBottom: "16px" }}>
        Editar suscripción grupal
      </Typography>
      <Typography variant="h6" style={{ marginBottom: "8px" }}>
        Código grupo:
      </Typography>
      <Typography style={{ marginBottom: "20px" }}>
        {'/planes-corporativos/join?hash='+props?.record?.hash}
      </Typography>
      <SelectInput
        source="status"
        label="Estado"
        choices={[
          { id: "pending", name: "Pendiente" },
          { id: "active", name: "Activa" },
          { id: "cancelled", name: "Cancelada" },
        ]}
      />
      <Typography variant="h6" style={{ marginBottom: "16px" }}>
        Titular
      </Typography>
      <Typography style={{ marginBottom: "8px" }}>
        {props?.record?.subscriber?.email}
      </Typography>
      <Typography style={{ marginBottom: "8px" }}>
        {props?.record?.subscriber?.external_user_id}
      </Typography>
      <Typography variant="h6" style={{ marginTop: "20px" }}>
        Suscriptos
      </Typography>
      <ul style={{ padding: 0, width: "100%" }}>
        {props?.record?.associations.map((i) => (
          <li
            key={i?.id}
            style={{
              display: "flex",
              gap: "20px",
              alignItems: "center",
              padding: "10px",
              boxSizing: "border-box"
            }}
          >
            {i?.subscriber?.email}{" "}
            <Button
              color="secondary"
              variant="contained"
              size="small"
              onClick={() => handleClick(i?.id)}
            >
              Eliminar
            </Button>
          </li>
        ))}
      </ul>
      <Confirm
        isOpen={open != undefined}
        onClose={() => setOpen()}
        onConfirm={() => handleDelete()}
        title="Esta seguro de eliminar la suscripción?"
        content="Se quitara al suscriptor del grupo"
      />
    </SimpleForm>
  );
};
export const SubscriptionGroupEdit = ({ ...props }) => {
  const notify = useNotify();

  return (
    <Edit title=" " {...props} aside={<AsideSubscriptions {...props}/>}>
      <FormEdit {...props} />
    </Edit>
  );
};
