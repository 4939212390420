import * as React from "react";
import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  UPDATE,
  SaveButton,
  SimpleForm,
  Toolbar,
  required,
  showNotification,
  NumberInput,
  minValue,
  number,
  SelectInput,
  DateInput,
  useGetList,
} from "react-admin";
import Button from "@material-ui/core/Button";
import IconCancel from "@material-ui/icons/Cancel";
import { InputAdornment } from "@material-ui/core";
import { useTranslate } from "ra-core";

const useStyles = makeStyles({
  form: { padding: 0 },
  button: {
    margin: "10px 24px",
    position: "relative",
    border: "1px solid #212121",
  },
  iconPaddingStyle: {
    paddingRight: "0.5em",
  },
  toolbar: {
    background: "#fff",
    display: "flex",
    justifyContent: "flex-end",
  },
});

const PostQuickCreateToolbar = ({ submitting, onCancel, ...props }) => {
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <Toolbar className={classes.toolbar} {...props}>
      <Button className={classes.button} onClick={onCancel}>
        <IconCancel className={classes.iconPaddingStyle} />
        {translate("ra.action.cancel")}
      </Button>
      <SaveButton />
    </Toolbar>
  );
};

const getArrayPlans = (plans, plansIds) => {
  const events = plans
    ? plansIds.map((id) => ({
        data: plans[id],
      }))
    : [];

  return events;
};

const SubscriptionQuickEdit = ({
  onCancel,
  onSave,
  record,
  subscriber_id,
  ...props
}) => {
  const classes = useStyles();
  const translate = useTranslate();
  const { data: plans, ids: plansIds } = useGetList(
    "plans",
    { page: 1, perPage: 100 },
    { field: "name", order: "ASC" }
  );
  const planes = getArrayPlans(plans, plansIds);
  const dispatch = useDispatch();
  const submitting = useSelector((state) => state.admin.loading > 0);
  const { id } = record;

  const handleSave = useCallback(
    (values) => {
      const dataToSend = {
        id: id,
        ...values,
        subscriber_id: subscriber_id ? subscriber_id : record?.subscriber_id,
      };

      dispatch({
        type: "QUICK_UPDATE",
        payload: { id: id, data: dataToSend },
        meta: {
          fetch: UPDATE,
          resource: "subscriptions",
          onSuccess: {
            callback: ({ payload: { data } }) => onSave(data),
          },
          onFailure: {
            callback: ({ error }) => {
              dispatch(showNotification(error, "error"));
            },
          },
        },
      });
    },
    [dispatch, onSave, id, subscriber_id]
  ); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <SimpleForm
      save={handleSave}
      saving={submitting}
      redirect={false}
      toolbar={
        <PostQuickCreateToolbar onCancel={onCancel} submitting={submitting} />
      }
      classes={{ form: classes.form }}
      initialValues={{
        payment_date: record.payment_date,
        paid_amount: record.paid_amount,
        expire_date: record.expire_date,
        status: record.status,
        plan_id: record.plan.id,
      }}
      {...props}
    >
      {planes ? (
        <SelectInput
          source="plan_id"
          choices={planes}
          optionText="data.name"
          optionValue="data.id"
          validate={required()}
          disabled={true}
        />
      ) : null}
      <DateInput
        label="components.payment_date"
        source="payment_date"
        value={
          record.payment_date
            ? new Date(record.payment_date + translate("components.locale_utc"))
            : undefined
        }
        validate={required()}
      />

      <NumberInput
        label="components.amount"
        source="paid_amount"
        min={0}
        validate={[number(), minValue(0)]}
        InputProps={{
          startAdornment: <InputAdornment position="start">$</InputAdornment>,
        }}
      />

      <DateInput
        label="resources.subscribers.fields.expire_date"
        source="expire_date"
        value={
          record.expire_date
            ? new Date(record.expire_date + translate("components.locale_utc"))
            : new Date()
        }
        validate={required()}
      />

      <SelectInput
        label="resources.subscribers.fields.status"
        source="status"
        choices={[
          {
            id: "active",
            name: `${translate("resources.subscribers.status.active")}`,
          },
          {
            id: "cancelled",
            name: `${translate("resources.subscribers.status.cancelled")}`,
          },
          {
            id: "expired",
            name: `${translate("resources.subscribers.status.expired")}`,
          },
        ]}
      />
    </SimpleForm>
  );
};

export default SubscriptionQuickEdit;
