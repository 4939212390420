import * as React from "react";
import {
  Datagrid,
  EditButton,
  Filter,
  List,
  TextField,
  TextInput,
  ReferenceField,
} from "react-admin";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import { ActionsCreate } from "../../components/Toolbar/ActionsCreate";
import { useRecordContext, useTranslate } from "ra-core";
import { Typography } from "@material-ui/core";

const PlansFilter = (props) => (
  <Filter {...props}>
    <TextInput source="search" label="ra.action.search" alwaysOn />
  </Filter>
);

const PriceField = ({ record }) => {
  const translate = useTranslate();
  if (!record && !record.price && !record.price_discount) return "";
  let multiplier = record?.limit > 0 ? record.limit : 1;

  if (record.price_discount > 0) {
    let priceTotalDiscount = record.price_discount;
    let priceTotal = record.price;
    if (record.type === "yearly") {
      priceTotalDiscount = priceTotalDiscount * 12 * multiplier;
      priceTotal = priceTotal * 12 * multiplier;
    }
    return (
      <>
        <del style={{ color: "#9a9a9a" }}>{`${Number(priceTotal).toLocaleString(
          translate("components.locale_language"),
          {
            style: "currency",
            currency: translate("components.currency"),
            minimumFractionDigits: 2,
          }
        )}`}</del>
        <br />
        <span>{`${Number(priceTotalDiscount).toLocaleString(
          translate("components.locale_language"),
          {
            style: "currency",
            currency: translate("components.currency"),
            minimumFractionDigits: 2,
          }
        )}`}</span>
      </>
    );
  } else {
    let priceTotal = record.price;
    if (record.type === "yearly") {
      priceTotal = priceTotal * 12 * multiplier;
    }
    return (
      <span>{`${Number(priceTotal).toLocaleString(
        translate("components.locale_language"),
        {
          style: "currency",
          currency: translate("components.currency"),
          minimumFractionDigits: 2,
        }
      )}`}</span>
    );
  }
};

const TypeContentField = ({ record }) => {
  return record ? (
    <span>
      {record.type && record.type === "monthly"
        ? "Mensual"
        : record.type === "yearly"
        ? "Anual"
        : ""}
    </span>
  ) : null;
};

const ButtonFilter = ({ record, source }) => {
  if (!record && !record.subscriptions_count) return null;
  return (
    <Button
      color="primary"
      component={Link}
      to={{
        pathname: `/members`,
        search: `filter=${JSON.stringify({ plans_ids: [record.id] })}`,
      }}
      onClick={(e) => e.stopPropagation()}
    >
      {record[`${source}`]}
    </Button>
  );
};

const ButtonShare = () => {
  const record = useRecordContext();
  const [copy, setCopy] = React.useState();
  if (!record?.corporative) return null;
  const handleClick = () => {
    const input = document.createElement("textarea");
    input.value =
      process.env.REACT_APP_URL_CLIENT + "/planes-corporativos/" + record?.slug;
    document.body.appendChild(input);
    input.select();
    document.execCommand("copy");
    document.body.removeChild(input);
    setCopy(true);
    setTimeout(() => setCopy(false), 3000);
  };
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
      <Button onClick={() => handleClick()} color="primary">
        {copy ? "Copiado" : "Link"}
      </Button>
    </div>
  );
};

export const PlansList = (props) => {
  const translate = useTranslate();

  return (
    <List
      filters={<PlansFilter />}
      actions={<ActionsCreate label={`${translate("resources.plans.new")}`} />}
      filter={{ type_not_null: true }}
      {...props}
      bulkActionButtons={false}
      title=" "
    >
      <Datagrid>
        <TextField source="name" />
        <PriceField source="price_total" sortable={false} />
        <TypeContentField source="type" />
        <ReferenceField
          label="components.profile"
          source="role_id"
          reference="roles"
        >
          <TextField source="title" />
        </ReferenceField>
        <ButtonFilter source="subscriptions_count" />
        <EditButton />
        <ButtonShare />
      </Datagrid>
    </List>
  );
};
