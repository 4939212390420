import { Edit, SimpleForm } from "react-admin";
import { Form, validate } from "./create";
import { apiCOD } from "../../../utils/utilsFetchBenefits";
import { ActionSaveDelete } from "../../../components/Toolbar/ActionSaveDelete";
import { ActionsBack } from "../../../components/Toolbar/ActionsBack";

export const WorkshopEdit = ({ ...props }) => {
  const transform = (values) => {
    let copy = { ...values };
    copy.company = apiCOD;
    if (!values?.workshopdates) {
      copy.workshopdates = [];
    }
    return copy;
  };
  return (
    <Edit
      title=" "
      transform={transform}
      {...props}
      actions={<ActionsBack />}
      mutationMode="pessimistic"
    >
      <SimpleForm
        toolbar={
          <ActionSaveDelete
            permissions={props?.permissions}
            permissionDelete="delete-workshop"
            permissionSave="edit-workshop"
          />
        }
        redirect={false}
        validate={validate}
      >
        <Form {...props} />
      </SimpleForm>
    </Edit>
  );
};
