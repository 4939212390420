import * as React from "react";
import {
  AutocompleteInput,
  Create,
  ImageField,
  ImageInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
} from "react-admin";
import { Box, Typography, Grid } from "@material-ui/core";
import { useTranslate } from "ra-core";

const FormCreatePayment = (props) => {
  const translate = useTranslate();
  return (
    <Grid
      style={{ width: "100%" }}
      sm={12}
      md={6}
      display={{ md: "block", lg: "flex" }}
    >
      <Box flex={3} mr={{ md: 0, lg: "1em" }}>
        <Box display={{ xs: "block", sm: "flex" }}>
          <Box flex={1} style={{ alignSelf: "center" }}>
            <ImageInput
              source="file"
              label="Imagen"
              accept="image/*,.pdf"
              maxSize="2000000"
              validate={[required()]}
            >
              <ImageField source="src" title="title" />
            </ImageInput>
          </Box>
        </Box>
        <Box display={{ xs: "block", sm: "flex" }}>
          <SelectInput
            source="status"
            label="Estado"
            defaultValue={"pending"}
            choices={[
              { id: "pending", name: "Pendiente" },
              { id: "approved", name: "Aprobado" },
              { id: "rejected", name: "Rechazado" },
            ]}
          />
        </Box>
        <TextInput
          source="comments"
          label="Comentarios"
          multiline
          rows={3}
          fullWidth
        />
        <TextInput
          source="external_user_id"
          label="Id usuario pagina 12"
          validate={[required()]}
        />
        <ReferenceInput
          source="plan_id"
          reference="plans"
          validate={[required()]}
          filterToQuery={(searchText) => ({
            corporative: true,
          })}
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
      </Box>
    </Grid>
  );
};

export const TransferPaymentsCreate = (props) => {
  return (
    <Create {...props} title=" ">
      <SimpleForm>
        <FormCreatePayment />
      </SimpleForm>
    </Create>
  );
};
