import * as React from "react";
import { createElement } from "react";
import { Card, Box, Typography, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  card: {
    minHeight: 52,
    display: "flex",
    flexDirection: "column",
    flex: "1",
    "& a": {
      textDecoration: "none",
      color: "inherit",
    },
  },
  main: {
    overflow: "inherit",
    padding: 16,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .icon": {
      color: theme.palette.type === "dark" ? "inherit" : "#dc2440",
    },
  },
  accordion: {
    minHeight: 52,
    display: "flex",
    flexDirection: "column",
    flex: "1",
  },
  summary: {
    height: "100px",
    border: "0!important",
  },
  title: {},
}));

const CardWithIcon = (props) => {
  const { icon, title, subtitle, children, collapse } = props;
  const classes = useStyles(props);

  return collapse ? (
    <Card className={classes.card}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        style={{ padding: "10px 20px" }}
      >
        <Box width="3em" className="icon">
          {createElement(icon, { fontSize: "large", color: "primary" })}
        </Box>
        <Box textAlign="right">
          <Typography className={classes.title} color="textSecondary">
            {title}
          </Typography>
          <Typography variant="h5" component="h2">
            {subtitle}
          </Typography>
        </Box>
        {children && <Divider />}
        {children}
      </Box>
      <Box>
        <ul style={{ listStyle: "none", paddingLeft: "20px", marginTop: 0 }}>
          {collapse
            ? Object.entries(collapse).map(([clave, valor]) => (
                <li>
                  <Typography variant="body1" component="span">
                    <strong>{clave}</strong>: {valor}
                  </Typography>
                </li>
              ))
            : null}
        </ul>
      </Box>
    </Card>
  ) : (
    <Card className={classes.card}>
      <div className={classes.main}>
        <Box width="3em" className="icon">
          {createElement(icon, { fontSize: "large", color: "primary" })}
        </Box>
        <Box textAlign="right">
          <Typography className={classes.title} color="textSecondary">
            {title}
          </Typography>
          <Typography variant="h5" component="h2">
            {subtitle}
          </Typography>
        </Box>
      </div>
      {children && <Divider />}
      {children}
    </Card>
  );
};

export default CardWithIcon;
