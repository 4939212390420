import {
  Datagrid,
  DeleteButton,
  ImageField,
  List,
  TextField,
} from "react-admin";
import { ActionsCreate } from "../../../components/Toolbar/ActionsCreate";
import { makeStyles } from "@material-ui/styles";
import { checkPermissions } from "../../../utils/validations";

const useStyles = makeStyles({
  img: {
    width: "60px!important",
    height: "60px!important",
    "& img": {
      maxWidth: "150px",
      maxHeight: "60px!important",
      padding: 0,
      margin: 0,
    },
  },
});

export const BenefitAssetsList = (props) => {
  const classes = useStyles();
  const canCreate = checkPermissions(
    props?.permissions,
    "create-benefit-assets"
  );

  return (
    <List
      {...props}
      bulkActionButtons={false}
      title=" "
      sort={{ field: "created_at", order: "ASC" }}
      actions={<ActionsCreate createButton={canCreate} />}
    >
      <Datagrid rowClick={"show"}>
        <ImageField source="url" className={classes.img} />
        <TextField source="alt" />
        <DeleteButton />
      </Datagrid>
    </List>
  );
};
