import * as React from "react";
import {
  Edit,
  TextInput,
  Toolbar,
  FormWithRedirect,
  required,
  SelectInput,
  ImageField,
  Link,
} from "react-admin";
import { Box, Card, CardContent, Typography } from "@material-ui/core";
import { useTranslate } from "ra-core";

export const TransferPaymentsEdit = (props) => {
  return (
    <Edit title=" " {...props} component="div">
      <PaymentForm />
    </Edit>
  );
};

const PaymentForm = (props) => {
  const translate = useTranslate();
  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => (
        <Card>
          <form>
            <CardContent>
              <Box display={{ md: "block", lg: "flex" }}>
                <Box flex={2} mr={{ md: 0, lg: "1em" }}>
                  <Typography variant="h6" gutterBottom>
                    Editar transferencia (
                    {formProps.record && formProps.record.id})
                  </Typography>
                  <Box display={{ xs: "block", sm: "flex" }}>
                    <Box flex={1} style={{ alignSelf: "center" }}></Box>
                  </Box>
                  <ImageField source="preview_url" />
                  <Box>
                    <a
                      href={formProps.record?.preview_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Link imagen
                    </a>
                  </Box>
                  <Typography style={{margin: '16px 0'}}>
                    Monto a abonar: $
                    {formProps.record?.subscription?.paid_amount}
                  </Typography>
                  <Typography>
                    
                  </Typography>
                  <SelectInput
                    source="status"
                    label="Estado"
                    choices={[
                      { id: "pending", name: "Pendiente" },
                      { id: "approved", name: "Aprobado" },
                      { id: "rejected", name: "Rechazado" },
                    ]}
                  />
                  <TextInput
                    source="comments"
                    label="Comentarios"
                    multiline
                    rows={3}
                    fullWidth
                  />
                </Box>
                <Box
                  flex={1}
                  ml={{ xs: 0, lg: "1em" }}
                  mt={{ xs: "1em", lg: 0 }}
                ></Box>
              </Box>
            </CardContent>
            <Toolbar
              record={formProps.record}
              basePath={formProps.basePath}
              pristine={formProps.pristine}
              undoable={true}
              invalid={formProps.invalid}
              handleSubmit={formProps.handleSubmit}
              saving={formProps.saving}
              resource="transfer_payments"
            />
          </form>
        </Card>
      )}
    />
  );
};
