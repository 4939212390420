import { Resource } from "react-admin";

import UserIcon from "@material-ui/icons/Group";
import RoleIcon from "@material-ui/icons/HowToReg";
import AssignmentIcon from "@material-ui/icons/AssignmentTurnedIn";
import { ContactFormCreate } from "../../views/contact_form_create";

import {
  JournalistCreate,
  JournalistEdit,
  JournalistList,
} from "../../views/journalists";
import { MemberCreate, MemberEdit, MemberList } from "../../views/members";
import { RoleList, RoleEdit, RoleCreate } from "../../views/roles";
import { PlansCreate, PlansEdit, PlansList } from "../../views/plans";
import ViewSubscriptions from "../../views/metrics/view-subscriptions";
import { NonPermission } from "../../NonPermission";
import { messages } from "../../language/language-es";
/*** BENEFITS */
// import {
//   BenefitsList,
//   BenefitsCreate,
//   BenefitsEdit,
// } from "../../views/benefits/benefits";
// import {
//   BusinessList,
//   BusinessCreate,
//   BusinessEdit,
// } from "../../views/benefits/business";
//import ViewBenefits from "../../views/benefits/metric_benefits/ViewBenefits";
import { checkPermissions } from "../../utils/validations";
// import {
//   BenefitTypeList,
//   BenefitTypeCreate,
//   BenefitTypeEdit,
// } from "../../views/benefits/benefit_types";
import {
  BenefitCategoriesCreate,
  BenefitCategoriesEdit,
  BenefitCategoriesList,
} from "../../views/benefits/benefit_categories";
import {
  BenefitAssetsCreate,
  BenefitAssetsShow,
  BenefitAssetsList,
} from "../../views/benefits/benefit_assets";
import {
  WorkshopCreate,
  WorkshopEdit,
  WorkshopList,
} from "../../views/benefits/workshop";
import {
  SubscriptionWorkshopdateCreate,
  SubscriptionWorkshopdateEdit,
  SubscriptionWorkshopdateList,
} from "../../views/benefits/subscription_workshopdate";
import { ProvincesList } from "../../views/benefits/provinces/list";
import { ProvincesCreate, ProvincesEdit } from "../../views/benefits/provinces";
import {
  CitiesCreate,
  CitiesEdit,
  CitiesList,
} from "../../views/benefits/cities";
import {
  LocationsList,
  LocationsCreate,
  LocationsEdit,
} from "../../views/benefits/locations";
import {
  AuthorsCreate,
  AuthorsEdit,
  AuthorsList,
} from "../../views/benefits/authors";
import {
  RegisteredUsersEdit,
  RegisteredUsersList,
} from "../../views/registered_users";
import {
  TransferPaymentsCreate,
  TransferPaymentsEdit,
  TransferPaymentsList,
} from "../../views/transfer_payments";
import {
  SubscriptionGroupCreate,
  SubscriptionGroupList,
  SubscriptionGroupEdit,
} from "../../views/subscription_group";
/** END BENEFITS */

const Resources = (permissions) => [
  <Resource
    name="submenu_membership"
    options={{
      label: messages.resources.submenuMemberships.name,
      labelPlans: messages.resources.plans.name,
      labelSubscriptions: messages.resources.suscriptors.name,
      labelMetricSubscriptions:
        messages.resources.metrics.titles.title_subscriptions,
      labelSubscriptionGroup: "Suscripción grupal",
      labelTransferPayments: "Pagos transferencia",
    }}
  />,
  <Resource
    name="journalists"
    list={
      checkPermissions(permissions, "list-journalists") ? JournalistList : null
    }
    edit={
      checkPermissions(permissions, "edit-journalists")
        ? JournalistEdit
        : NonPermission
    }
    create={
      checkPermissions(permissions, "create-journalists")
        ? JournalistCreate
        : NonPermission
    }
    icon={UserIcon}
    options={{ label: messages.resources.journalists.name }}
  />,
  <Resource
    name="roles"
    list={checkPermissions(permissions, "list-roles") ? RoleList : null}
    edit={
      checkPermissions(permissions, "edit-roles") ? RoleEdit : NonPermission
    }
    create={
      checkPermissions(permissions, "create-roles") ? RoleCreate : NonPermission
    }
    icon={RoleIcon}
    options={{ label: messages.resources.roles.name }}
  />,

  <Resource name="users" />,
  <Resource name="subscribers" />,

  <Resource
    name="members"
    list={
      checkPermissions(permissions, "list-subscribers")
        ? MemberList
        : NonPermission
    }
    create={
      checkPermissions(permissions, "create-subscribers")
        ? MemberCreate
        : NonPermission
    }
    edit={
      checkPermissions(permissions, "edit-subscribers")
        ? MemberEdit
        : NonPermission
    }
  />,
  <Resource
    name="plans"
    list={
      checkPermissions(permissions, "list-plans") ? PlansList : NonPermission
    }
    create={
      checkPermissions(permissions, "create-plans")
        ? PlansCreate
        : NonPermission
    }
    edit={
      checkPermissions(permissions, "edit-plans") ? PlansEdit : NonPermission
    }
    icon={AssignmentIcon}
    options={{ label: messages.resources.plans.name }}
  />,
  <Resource
    name="metrics-subscriptions"
    list={
      checkPermissions(permissions, "list-metrics")
        ? ViewSubscriptions
        : NonPermission
    }
  />,
  <Resource name="contact_form" create={ContactFormCreate} />,
];

const BenefitsResources = (permissions) => [
  // <Resource
  //   name='business'
  //   list={
  //     checkPermissions(permissions, "list-shop") ? BusinessList : NonPermission
  //   }
  //   create={
  //     checkPermissions(permissions, "create-shop")
  //       ? BusinessCreate
  //       : NonPermission
  //   }
  //   edit={
  //     checkPermissions(permissions, "edit-shop") ? BusinessEdit : NonPermission
  //   }
  //   options={{ label: messages.resources.business.name }}
  // />,
  // <Resource
  //   name='metric_benefits'
  //   list={
  //     checkPermissions(permissions, "show-metrics-benefits")
  //       ? ViewBenefits
  //       : NonPermission
  //   }
  // />,
  <Resource
    name="submenu_club"
    options={{
      label: messages.resources.club.name,
      //labelBenefit: messages.resources.benefits.name,
      labelWorkshop: messages.resources?.workshop.name,
      //labelMetric: messages.resources.metrics.name,
      //labelBenefitType: messages.resources.benefit_types.name,
      labelBenefitCategories: messages.resources.benefit_categories.name,
      labelBenefitAssets: messages.resources.benefit_assets.name,
      labelAuthors: messages.resources.authors.name,
      labelSubscriptionWorkshopdate:
        messages.resources?.subscription_workshopdate?.name,
      labelProvinces: messages.resources.provinces.name,
      labelCities: messages.resources.cities.name,
      //labelBusiness: messages.resources.business.name,
      labelLocations: messages.resources.locations.name,
    }}
  />,
  // <Resource
  //   name='benefits'
  //   list={
  //     checkPermissions(permissions, "list-benefits")
  //       ? BenefitsList
  //       : NonPermission
  //   }
  //   create={
  //     checkPermissions(permissions, "create-benefits")
  //       ? BenefitsCreate
  //       : NonPermission
  //   }
  //   edit={
  //     checkPermissions(permissions, "edit-benefits")
  //       ? BenefitsEdit
  //       : NonPermission
  //   }
  // />,
  <Resource
    name="provinces"
    list={
      checkPermissions(permissions, "list-provinces")
        ? ProvincesList
        : NonPermission
    }
    create={
      checkPermissions(permissions, "create-provinces")
        ? ProvincesCreate
        : NonPermission
    }
    edit={
      checkPermissions(permissions, "edit-provinces")
        ? ProvincesEdit
        : NonPermission
    }
    options={{ label: messages.resources.provinces.name }}
  />,
  <Resource
    name="cities"
    list={
      checkPermissions(permissions, "list-cities") ? CitiesList : NonPermission
    }
    create={
      checkPermissions(permissions, "create-cities")
        ? CitiesCreate
        : NonPermission
    }
    edit={
      checkPermissions(permissions, "edit-cities") ? CitiesEdit : NonPermission
    }
    options={{ label: messages.resources.locations.name }}
  />,
  <Resource
    name="locations"
    list={
      checkPermissions(permissions, "list-locations")
        ? LocationsList
        : NonPermission
    }
    create={
      checkPermissions(permissions, "create-locations")
        ? LocationsCreate
        : NonPermission
    }
    edit={
      checkPermissions(permissions, "edit-locations")
        ? LocationsEdit
        : NonPermission
    }
    options={{ label: messages.resources.locations.name }}
  />,
  // <Resource
  //   name='benefit_types'
  //   list={
  //     checkPermissions(permissions, "list-benefits")
  //       ? BenefitTypeList
  //       : NonPermission
  //   }
  //   create={
  //     checkPermissions(permissions, "create-benefits")
  //       ? BenefitTypeCreate
  //       : NonPermission
  //   }
  //   edit={
  //     checkPermissions(permissions, "edit-benefits")
  //       ? BenefitTypeEdit
  //       : NonPermission
  //   }
  //   options={{ label: messages.resources.benefit_types.name }}
  // />,
  <Resource name="provinces" />,
  <Resource name="cities" />,
  <Resource
    name="transfer_payments"
    list={
      checkPermissions(permissions, "list-transfer-payments")
        ? TransferPaymentsList
        : NonPermission
    }
    create={
      checkPermissions(permissions, "create-transfer-payments")
        ? TransferPaymentsCreate
        : NonPermission
    }
    edit={
      checkPermissions(permissions, "edit-transfer-payments")
        ? TransferPaymentsEdit
        : NonPermission
    }
    options={{ label: "Pagos por transferencia" }}
  />,
  <Resource
    name="subscription_group_association"
    options={{ label: "Suscripciones asociadas" }}
  />,
  <Resource
    name="subscription_group"
    list={
      checkPermissions(permissions, "list-subscription-group")
        ? SubscriptionGroupList
        : NonPermission
    }
    create={
      checkPermissions(permissions, "create-subscription-group")
        ? SubscriptionGroupCreate
        : NonPermission
    }
    edit={
      checkPermissions(permissions, "edit-subscription-group")
        ? SubscriptionGroupEdit
        : NonPermission
    }
    options={{ label: "Suscripciones grupales" }}
  />,
  <Resource
    name="benefit_categories"
    list={
      checkPermissions(permissions, "list-benefit-categories")
        ? BenefitCategoriesList
        : NonPermission
    }
    create={
      checkPermissions(permissions, "create-benefit-categories")
        ? BenefitCategoriesCreate
        : NonPermission
    }
    edit={
      checkPermissions(permissions, "edit-benefit-categories")
        ? BenefitCategoriesEdit
        : NonPermission
    }
    options={{ label: messages.resources.benefit_categories.name }}
  />,
  <Resource
    name="benefit_assets"
    list={
      checkPermissions(permissions, "list-benefit-assets")
        ? BenefitAssetsList
        : NonPermission
    }
    create={
      checkPermissions(permissions, "create-benefit-assets")
        ? BenefitAssetsCreate
        : NonPermission
    }
    show={
      checkPermissions(permissions, "edit-benefit-assets")
        ? BenefitAssetsShow
        : NonPermission
    }
    options={{ label: messages.resources.benefit_assets.name }}
  />,

  <Resource
    name="workshop"
    create={
      checkPermissions(permissions, "create-workshop")
        ? WorkshopCreate
        : NonPermission
    }
    list={
      checkPermissions(permissions, "list-workshop")
        ? WorkshopList
        : NonPermission
    }
    edit={
      checkPermissions(permissions, "edit-workshop")
        ? WorkshopEdit
        : NonPermission
    }
    show={
      checkPermissions(permissions, "list-workshop")
        ? WorkshopEdit
        : NonPermission
    }
    options={{ label: messages.resources.workshop.name }}
  />,
  <Resource
    name="benefit_authors"
    create={
      checkPermissions(permissions, "create-benefit-authors")
        ? AuthorsCreate
        : NonPermission
    }
    list={
      checkPermissions(permissions, "list-benefit-authors")
        ? AuthorsList
        : NonPermission
    }
    edit={
      checkPermissions(permissions, "edit-benefit-authors")
        ? AuthorsEdit
        : NonPermission
    }
    options={{ label: messages.resources.benefit_authors.name }}
  />,
  <Resource
    name="subscription_workshopdate"
    create={
      checkPermissions(permissions, "create-subscription-workshopdate")
        ? SubscriptionWorkshopdateCreate
        : NonPermission
    }
    list={
      checkPermissions(permissions, "list-subscription-workshopdate")
        ? SubscriptionWorkshopdateList
        : NonPermission
    }
    edit={
      checkPermissions(permissions, "edit-subscription-workshopdate")
        ? SubscriptionWorkshopdateEdit
        : NonPermission
    }
    show={
      checkPermissions(permissions, "list-subscription-workshopdate")
        ? SubscriptionWorkshopdateEdit
        : NonPermission
    }
    options={{ label: messages.resources.subscription_workshopdate.name }}
  />,
  <Resource
    name="registered_users"
    list={
      checkPermissions(permissions, "list-registered-users")
        ? RegisteredUsersList
        : NonPermission
    }
    edit={
      checkPermissions(permissions, "edit-registered-users")
        ? RegisteredUsersEdit
        : NonPermission
    }
    options={{ label: messages.resources.subscribers.name }}
    permissions={permissions}
    icon={UserIcon}
  />,
];

const AllResources = (permissions) => {
  const current = Resources(permissions);
  const benefits = BenefitsResources(permissions);
  return [...benefits, ...current];
};

export default AllResources;
